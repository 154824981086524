import React from "react";
import "./New.scss";
import banner from "../../Media/New Launching/banner.png";
import data from "./data.json";
import Card from "../../Component/Card/Card";
import avanyaimg from "../../Media/New Launching/Avanya";
import avegaimg from "../../Media/New Launching/Avega";
import Studenthouse from "./studenthouse/studenthouse.jsx";

const New = () => {
  const imageMap = {
    avanyaimg,
    avegaimg,
  };
  return (
    <div className="newcont">
      <div className="newcenter">
        <div id="avanya" className="title">
          New Launching
        </div>
        <Studenthouse />
        <div className="subtitle">Avanya & Avega at Patragriya</div>
        <div className="banner">
          <img className="bannerimg" alt="newlaunching" src={banner} />
        </div>
        <div className="cardsec">
          {data.cards.map((card, index) => {
            // Determine the logo dynamically

            return (
              <Card
                key={index}
                carousel={imageMap[card.imagesKey]}
                cardtitle={card.cardtitle} // Dynamically import logo images
                title={card.title}
                harga={card.harga}
                price={card.price}
                subprice={card.subprice}
                details={card.details}
                whatsappLink={card.whatsappLink}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default New;
