import React from "react";
import "./Footer.scss";
import logo from "../../Media/LOGO-POPARK-PNG-2048x1184-1-1536x888.png-1.webp";

const Footer = () => {
  return (
    <div className="footercont">
      <div className="footercenter1">
        <div className="left">
          <div className="title">Marketing Gallery</div>
          <div className="subtitle">Podomoro Park</div>
          <div className="alamat">
            Jl. Podomoro Boulevard Utara No.1, Lengkong, Kec. Bojongsoang,
            Kabupaten Bandung, Jawa Barat 40354
          </div>
          <div className="contact">Contact Us : +6285795865769</div>
        </div>
        <div className="right">
          <img className="footerlogo" src={logo} alt="podomoro logo" />
        </div>
      </div>
      <div className="footerbottm">
        <hr />
        PODOMORO PARK BANDUNG | 2023
      </div>
    </div>
  );
};

export default Footer;
